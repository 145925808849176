import React from 'react';

import backgroundImage from './pic.jpg'; 

function AboutApp() {
  return (
    <div>
      {/* Hero Section */}
      <section 
        className="hero" 
        style={{ 
          backgroundImage: `url(${backgroundImage})`, 
          backgroundSize: 'cover', 
          backgroundPosition: 'center', 
          color: 'white', 
          textAlign: 'center', 
          padding: '100px 20px',
          position: 'relative',
        }}
      >
        <div className="container" style={{ position: 'relative', zIndex: 2 }}>
          <h1>About the App</h1>
          <p>Kofa X is your ultimate campus social platform, connecting students in every way possible.</p>
        </div>
        <div 
          style={{
            position: 'absolute', 
            top: 0, 
            left: 0, 
            right: 0, 
            bottom: 0, 
            backgroundColor: 'rgba(0, 0, 0, 0.5)', 
            zIndex: 1,
          }}
        ></div>
      </section>

      {/* Content Section */}
      <section className="container mt-5">
        <h3>Key Features</h3>
        <div className="row">
          <div className="col-md-6 mb-4">
            <div className="content-box p-4 h-100">
              <h4>Stay Updated</h4>
              <p>Receive real-time updates on campus events, news, and announcements.</p>
            </div>
          </div>
          <div className="col-md-6 mb-4">
            <div className="content-box p-4 h-100">
              <h4>Chat with Fellow Students</h4>
              <p>Instant messaging to connect with friends and classmates.</p>
            </div>
          </div>
          <div className="col-md-6 mb-4">
            <div className="content-box p-4 h-100">
              <h4>Advertise Skills and Products</h4>
              <p>Showcase your talents and offerings to the campus community.</p>
            </div>
          </div>
          <div className="col-md-6 mb-4">
            <div className="content-box p-4 h-100">
              <h4>Secure and Private</h4>
              <p>We prioritize your privacy and data security with robust encryption methods.</p>
            </div>
          </div>
        </div>
      </section>

      {/* Image Gallery Section */}
      <section className="container mt-5">
        <h3>App Screenshots</h3>
        <div className="row">
          <div className="col-md-4 mb-4">
            <img src="/images/kofa-x-4.jpg" alt="Screenshot 1" className="img-fluid rounded shadow-sm" />
          </div>
          <div className="col-md-4 mb-4">
            <img src="images/kofa-x-5.jpg" alt="Screenshot 2" className="img-fluid rounded shadow-sm" />
          </div>
          <div className="col-md-4 mb-4">
            <img src="/images/kofa-x-6.jpg" alt="Screenshot 3" className="img-fluid rounded shadow-sm" />
          </div>
        </div>
      </section>

      {/* How It Works Section */}
      <section className="container mt-5">
        <div className="content-box p-4">
          <h3>How It Works</h3>
          <p>Download the app, sign up using your university email, and start exploring the features tailored to enhance your campus experience.</p>
        </div>
      </section>
    </div>
  );
}

export default AboutApp;
