import React from 'react';
import backgroundImage from './pic.jpg';

function DownloadPage() {
  return (
    <div>
      {/* Hero Section */}
      <section className="hero" style={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: 'cover', 
        backgroundPosition: 'center', 
        color: "#fff",
        padding: "100px 20px",
        textAlign: "center"
      }}>
        <div className="container">
          <h1>Get Kofa X Today</h1>
          <p>Your ultimate social media app for university students. Stay connected, informed, and engaged.</p>
        </div>
      </section>

      {/* Download Section */}
      <div className="container mt-5">
        <h2>Download Kofa X</h2>
        <p>You can download the app directly from the links below:</p>
        <a href="https://drive.google.com/uc?export=download&id=1bhn-YNE3an0cOeHgc6ov9xGksnq4cOVC" className="btn btn-primary btn-lg m-2">
          Download for Android
        </a>
        <a href="link-to-your-app.ipa" className="btn btn-secondary btn-lg m-2">
          Download for iOS
        </a>
      </div>
    </div>
  );
}

export default DownloadPage;
