import React from 'react';
import backgroundImage from './pic.jpg';

function AboutUs() {
  return (
    <div>
      {/* Hero Section */}
      <section 
        className="hero" 
        style={{ 
          backgroundImage: `url(${backgroundImage})`, 
          backgroundSize: 'cover', 
          backgroundPosition: 'center', 
          color: 'white', 
          textAlign: 'center', 
          padding: '100px 20px',
          position: 'relative',
        }}
      >
        <div className="container" style={{ position: 'relative', zIndex: 2 }}>
          <h1>About Us</h1>
          <p>Connecting students, empowering communities.</p>
        </div>
        <div 
          style={{
            position: 'absolute', 
            top: 0, 
            left: 0, 
            right: 0, 
            bottom: 0, 
            backgroundColor: 'rgba(0, 0, 0, 0.5)', 
            zIndex: 1,
          }}
        ></div>
      </section>

      {/* Content Section */}
      <section className="container mt-5">
        <div className="row">
          <div className="col-md-4 mb-4">
            <div className="content-box p-4 h-100">
              <h3>Our Vision</h3>
              <p>
                To be the leading platform for student interaction and engagement across universities worldwide.
              </p>
            </div>
          </div>
          <div className="col-md-8 mb-4">
            <div className="content-box p-4 h-100">
              <h3>Our Values</h3>
              <ul>
                <li><strong>Innovation:</strong> We continuously strive to bring new features and improvements to our app.</li>
                <li><strong>Community:</strong> We are dedicated to building a strong and supportive student community.</li>
                <li><strong>Integrity:</strong> We maintain transparency and trustworthiness in everything we do.</li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      {/* Team Section */}
      <section className="team container mt-5">
        <h3 className="text-center mb-4">Meet the Team</h3>
        <div className="row">
          <div className="col-md-4 text-center mb-4">
            <div className="team-box p-4 h-100">
              <img src="path-to-team-member1.jpg" alt="Team Member 1" className="img-fluid rounded-circle mb-3" />
              <h5>John Doe</h5>
              <p>CEO & Founder</p>
            </div>
          </div>
          <div className="col-md-4 text-center mb-4">
            <div className="team-box p-4 h-100">
              <img src="path-to-team-member2.jpg" alt="Team Member 2" className="img-fluid rounded-circle mb-3" />
              <h5>Jane Smith</h5>
              <p>CTO & Co-Founder</p>
            </div>
          </div>
          <div className="col-md-4 text-center mb-4">
            <div className="team-box p-4 h-100">
              <img src="path-to-team-member3.jpg" alt="Team Member 3" className="img-fluid rounded-circle mb-3" />
              <h5>Michael Johnson</h5>
              <p>COO</p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}


export default AboutUs;
