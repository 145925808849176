import React from 'react';
import backgroundImage from './pic.jpg'; 

function Testimonials() {
  return (
    <div>
      {/* Hero Section */}
      <section 
        className="hero" 
        style={{ 
          backgroundImage: `url(${backgroundImage})`, 
          backgroundSize: 'cover', 
          backgroundPosition: 'center', 
          color: 'white', 
          textAlign: 'center', 
          padding: '100px 20px',
          position: 'relative',
        }}
      >
        <div className="container" style={{ position: 'relative', zIndex: 2 }}>
          <h1>Testimonials</h1>
          <p>See what our users are saying about Kofa X</p>
        </div>
        <div 
          style={{
            position: 'absolute', 
            top: 0, 
            left: 0, 
            right: 0, 
            bottom: 0, 
            backgroundColor: 'rgba(0, 0, 0, 0.5)', 
            zIndex: 1,
          }}
        ></div>
      </section>

      {/* Content Section */}
      <div className="container mt-5">
        <h2>What Students Say</h2>
        <div className="row">
          <div className="col-md-4 mb-4">
            <div className="content-box p-4 h-100">
              <blockquote className="blockquote mb-0">
                <p>"Kofa X has made it so easy to stay connected with what's happening on campus. I love it!"</p>
                <footer className="blockquote-footer mt-3">Osas, 300L Banking and Finance</footer>
              </blockquote>
            </div>
          </div>
          <div className="col-md-4 mb-4">
            <div className="content-box p-4 h-100">
              <blockquote className="blockquote mb-0">
                <p>"The chat feature is super convenient. I can quickly reach out to my classmates for group projects."</p>
                <footer className="blockquote-footer mt-3">Mary, 200L English and Literature</footer>
              </blockquote>
            </div>
          </div>
          <div className="col-md-4 mb-4">
            <div className="content-box p-4 h-100">
              <blockquote className="blockquote mb-0">
                <p>"I’ve been able to advertise my tutoring services and gain new clients through Kofa X."</p>
                <footer className="blockquote-footer mt-3">Victor, 400L Civil Engineering</footer>
              </blockquote>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Testimonials;
