import React from 'react';
import backgroundImage from './pic.jpg'; 

function HomePage() {
  return (
    <div>
      {/* Hero Section */}
      <section 
        className="hero" 
        style={{ 
          backgroundImage: `url(${backgroundImage})`, 
          backgroundSize: 'cover', 
          backgroundPosition: 'center', 
          color: 'white', 
          textAlign: 'center', 
          padding: '100px 20px',
          position: 'relative',
          minHeight: '50vh',
          width: '100%',
          
        }}
      >
        <div className="container" style={{ position: 'relative', zIndex: 2 }}>
          <h1>Welcome to Kofa X</h1>
          <p>Your ultimate social media app for university students.</p>
          <a href="https://drive.google.com/uc?export=download&id=1bhn-YNE3an0cOeHgc6ov9xGksnq4cOVC" className="btn btn-primary btn-lg">Download Now</a>
        </div>
        <div 
          style={{
            position: 'absolute', 
            top: 0, 
            left: 0, 
            right: 0, 
            bottom: 0, 
            backgroundColor: 'rgba(0, 0, 0, 0.5)', 
            zIndex: 1,
          }}
        ></div>
      </section>
      
      {/* Features Section */}
      <section className="features container mt-5">
        <h2 className="text-center mb-4">Features</h2>
        <div className="row">
          <div className="col-md-4">
            <div className="feature-box text-center p-4">
              <i className="bi bi-graph-up" style={{ fontSize: '2rem', color: '#4B0082' }}></i>
              <h3 className="mt-3">Stay Updated</h3>
              <p>Get the latest news and updates from your university.</p>
            </div>
          </div>
          <div className="col-md-4">
            <div className="feature-box text-center p-4">
              <i className="bi bi-chat-dots" style={{ fontSize: '2rem', color: '#4B0082' }}></i>
              <h3 className="mt-3">Chat with Friends</h3>
              <p>Connect and chat with fellow students in real-time.</p>
            </div>
          </div>
          <div className="col-md-4">
            <div className="feature-box text-center p-4">
              <i className="bi bi-bullhorn" style={{ fontSize: '2rem', color: '#4B0082' }}></i>
              <h3 className="mt-3">Advertise</h3>
              <p>Showcase your skills and products to other students.</p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default HomePage;
